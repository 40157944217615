import $ from 'jquery';

function Matchheight(item) {
  $(window).on('load resize', () => {
    const parents = document.querySelectorAll('.js-mheight');

    parents.forEach((parent) => {
      const elements = parent.querySelectorAll(item);

      elements.forEach((element) => (element.style.height = 'auto'));

      let maxHeight = 0;

      elements.forEach((element) => {
        if (element.clientHeight > maxHeight) maxHeight = element.clientHeight;
      });

      if ($(window).outerWidth() > 750)
        elements.forEach(
          (element) => (element.style.height = `${maxHeight}px`),
        );
      else elements.forEach((element) => element.removeAttribute('style'));
    });
  });
}
Matchheight('.c-list4__name');
