import $ from 'jquery';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

let slides = $('#slideshow').children(),
  currentSlide = 0,
  slideInterval = setInterval(nextSlide, 5000);

function nextSlide() {
  slides.removeClass('swiper-slide-next').removeClass('swiper-slide-active');
  $(slides[currentSlide])
    .addClass('swiper-slide-active')
    .removeClass('swiper-slide-next');
  currentSlide = (currentSlide + 1) % slides.length;
  $(slides[currentSlide])
    .addClass('swiper-slide-next')
    .removeClass('swiper-slide-active');
}

$('#mv_text .c-mv1__title').html(function (index, html) {
  return html.replace(/\S/g, '<span>$&</span>');
});

$('.js-animationText .js-animationText-wrap').html(function (index, html) {
  return html.replace(/\S/g, '<span>$&</span>');
});

$(window).on('load', function () {
  $('#mv_text').addClass('is-active');
  $('.c-mv1 .c-box1').addClass('is-active');
  $('#is-scrolldown').addClass('is-active');

  let $count1 = 0.01,
    $count2 = 0.02,
    $count3 = 0.01,
    $count4 = 0.02;

  $('#mv_text .c-mv1__title > span').each(function () {
    $(this).css({
      'transition-delay': $count2 + 's',
    });
    $count1 = $count1 + 0.02;
    $count2 = $count2 + 0.04;
  });


  $('.js-animationText').each(function () {
    let delayaos = $(this).data('aos-delay');
    if (delayaos) {
      $count3 = 0.01 + (delayaos / 1000),
        $count4 = 0.02 + (delayaos / 1000);
    } else {
      $count3 = 0.01,
        $count4 = 0.02;
    }
    if($(this).hasClass('is-news')){
      $(this).find('.js-animationText-wrap > span').each(function () {
        if($(this).index() === 0){
          $(this).css({
            'transition-delay': $count4 + 1 * 0.15 + 's',
          });
        }else{
          $(this).css({
            'transition-delay': $count4 + $(this).index() * 0.2 + 's',
          });
        }
      });
    }else{
      $(this).find('.js-animationText-wrap > span').each(function () {
      $(this).css({
        'transition-delay': $count4 + $(this).index() * 0.07 + 's',
      });
    });
    }
  })

  setTimeout(() => {
    $('#is-scrolldown').addClass('is-hide');
  }, 4000);

  setTimeout(() => {
    $('#is-scrolldown').removeClass('is-active');
  }, 5500);

  setInterval(() => {
    setTimeout(() => {
      $('#is-scrolldown').addClass('is-hide');
    }, 4000);

    setTimeout(() => {
      $('#is-scrolldown').removeClass('is-active');
    }, 5500);

    $('#is-scrolldown').removeClass('is-hide').addClass('is-active');
  }, 8000);
});

$(function () {
  let init = false;
  function swiperActivities() {
    if ($('body').hasClass('page-activities')) {
      if (window.innerWidth <= 767) {
        if (!init) {
          init = true;
          $('.js-slider1-swiper').each(function () {
            this.activitiesSwiper = new Swiper(this, {
              loop: true,
              slidesPerView: 'auto',
              spaceBetween: 10,
              navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              },
            });
          });
        }
      } else if (init) {
        $('.js-slider1-swiper').each(function () {
          this.activitiesSwiper.destroy();
        });
        init = false;
      }
    }
  }
  swiperActivities();
  $(window).on('resize', swiperActivities);
});
