import $ from 'jquery';

$(function () {
  $('.js-accordion').each(function () {
    $('.js-accordionHeading', this).on('click', function () {
      $(this).toggleClass('is-open');
      $(this)
        .closest('.js-accordion')
        .find('.js-accordionInfo')
        .stop()
        .slideToggle();
    });
  });
});
