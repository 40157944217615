import $ from 'jquery';

let scrollPosi;
$('.js-modal').each(function () {
  $(this).on('click', function () {
    scrollPosi = $(window).scrollTop();
    $('body').css({
      position: 'fixed',
      'overflow-y': 'scroll',
      width: '100%',
      'z-index': '1',
      top: -scrollPosi,
    });
    let dataModal = $(this).data('modal');
    $('.js-modalContent[data-modal=' + dataModal + ']').addClass('is-show');
    return false;
  });
});

$('.js-modalContent').click(function (event) {
  if (event.currentTarget !== event.target) {
    return;
  }
  $('.js-modalClose').trigger('click');
});
$('.js-modalClose').on('click', function () {
  $('body').removeAttr('style');
  $('html,body').animate({ scrollTop: scrollPosi }, 0);
  $('.js-modalContent').removeClass('is-show');
});
