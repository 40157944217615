import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// gsap.registerPlugin(ScrollTrigger);

// const tl = gsap.timeline({
//   scrollTrigger: {
//     trigger: '.p-top__box',
//     pin: false,
//     start: 'top top',
//     end: '50%',
//     scrub: 1,
//   },
// });

// if ($(window).outerWidth() > 767) {
//   tl.to('.js-circle .c-circle1__inner', {
//     duration: 10,
//     scale: 25,
//     top: '-75%',
//   });

//   const tl2 = gsap.timeline({
//     scrollTrigger: {
//       trigger: '.c-news',
//       pin: false,
//       start: 'top 50%',
//       end: '50%',
//       scrub: 1,
//     },
//   });

//   tl2.to('.js-circle .c-circle1__inner', {
//     duration: 10,
//     scale: 0,
//     bottom: '50%',
//   });
// } else {
//   tl.to('.js-circle .c-circle1__inner', {
//     duration: 10,
//     scale: 24,
//     top: '-75%',
//   });

//   const tl2 = gsap.timeline({
//     scrollTrigger: {
//       trigger: '.c-news',
//       pin: false,
//       start: 'top 50%',
//       end: '+=100%',
//       scrub: 1,
//     },
//   });

//   tl2.to('.js-circle .c-circle1__inner', {
//     duration: 10,
//     scale: 0,
//     bottom: '50%',
//   });
// }

$(window).on('load scroll', function () {
  var winSc = $(window).scrollTop();
  if ($('.p-top__box').length > 0) {
    var start = $('.p-top__box').offset().top - $(window).height() / 2;

    if (winSc > start) {
      $('.js-circle .c-circle1__inner').css('transform', 'scale(28)');
    } else {
      $('.js-circle .c-circle1__inner').css('transform', 'scale(0)');
    }
  }
});
