import $ from 'jquery';
import Scrollbar from 'smooth-scrollbar';
import '../Components/_Header.js';
import '../Components/_Anchor.js';
import '../Components/_Accordion.js';
import '../Components/_Modal.js';
import '../Components/_FadeIn.js';
import '../Components/_Navi.js';
import '../Components/_Gsap.js';
import '../Components/_Scroll.js';
import '../Components/_Matchheight.js';
import Aos from '../Components/_Aos.js';
import Swiper from '../Components/_Slider.js';

$('.is-navsub').on('click', function (event) {
  if ($(window).innerWidth() < 768) {
    event.preventDefault();
    if ($(this).hasClass('is-active')) {
      $(this).removeClass('is-active');
    } else {
      $(this).addClass('is-active');
    }
    $(this).siblings().slideToggle();
  }
});

if ($(window).innerWidth() > 767) {
  $('#js-tabs li a:not(:first)').addClass('inactive');
  $('.c-tabs__item2').hide();
  $('.c-tabs__item2:first').show();
}

$('#js-tabs li a').on('click', function (event) {
  if ($(window).innerWidth() > 767) {
    let id = $(this).attr('id');

    if ($(this).hasClass('inactive')) {
      $('#js-tabs li a').addClass('inactive');
      $(this).removeClass('inactive');

      $('.c-tabs__item2').hide();
      $('#' + id + 'C').fadeIn('slow');
    }
  }
});

setInterval(() => {
  if ($(window).scrollTop() != 0 && $(window).scrollTop() < ($('.c-footer').offset().top - $(window).outerHeight())) {
    $('#scrolltop').removeClass('is-scrolling');
  }
}, 1000);

$(window).on('scroll', function () {
  $('#scrolltop').addClass('is-scrolling');
  if ($(window).scrollTop() != 0) {
    if ($(window).scrollTop() > 300) {
      $('#scrolltop').addClass('is-show');
    } else {
      $('#scrolltop').removeClass('is-show');
    }
  } else {
    $('#scrolltop').addClass('is-scrolling');
  }
});

$(window).on('load', function () {
  if ($(window).scrollTop() != 0 && $(window).scrollTop() < ($('.c-footer').offset().top - $(window).outerHeight())) {
    $('#scrolltop').addClass('is-show').removeClass('is-scrolling');
  } else {
    $('#scrolltop').removeClass('is-show').addClass('is-scrolling');
  }
});

$(function () {
  let button = $('#show-more-list4');
  let list = $('#js-list4 li');
  let numToShow = 15;
  let numInList = list.length;
  //show more list4
  if ($(window).innerWidth() < 768) {
    numInList = list.length;
    list.hide();
    if (numInList > numToShow) {
      button.parent().show();
    }
    list.slice(0, numToShow).show();
  } else {
    list.show();
  }
  button.on('click', function () {
    let showing = list.filter(':visible').length;
    list.slice(showing - 1, showing + numToShow).fadeIn();
    let nowShowing = list.filter(':visible').length;
    if (nowShowing >= numInList) {
      button.parent().hide();
    }
  });

  //show more text
  let buttontext = $('#js-show-more');
  let content = $('#js-more-text');
  buttontext.on('click', function () {
    if ($(window).innerWidth() < 768) {
      content.show();
      buttontext.hide();
    }
  });
});

Scrollbar.initAll({
  alwaysShowTracks: true,
});

$('.js-product').on('click', function () {
  let cat = $(this).data('cat');
  $('.js-product').removeClass('is-active');
  $(this).addClass('is-active');
  if (cat == 'all') {
    $('.c-accordion1__item').removeAttr('style');
    $('.c-accordion1__ttl').removeAttr('style').removeClass('is-open');
    $('.c-accordion1__body').removeAttr('style');
  } else {
    $('.c-accordion1__item').hide();
    $('#' + cat).show();
    $('#' + cat + ' .c-accordion1__ttl').hide();
    $('#' + cat + ' .c-accordion1__body').show();
  }
});
