import $ from 'jquery';

$('.js-navi-item').each(function () {
  $(this).on('click', function (e) {
    e.preventDefault();
    $('.js-navi-item').removeClass('is-active');
    $(this).addClass('is-active');

    let area = $(this).data('area');

    if (area == 'all') {
      $('.js-filterItem').show();
    } else {
      $('.js-filterItem').hide();
      $('.js-filterItem[data-area=' + area + ']').show();
    }

    if ($(window).innerWidth() < 768) {
      let parents = $(this).closest('.js-navi');
      if (parents.hasClass('is-active')) {
        parents.removeClass('is-active');
      } else {
        parents.addClass('is-active');
      }

      let value = $(this).text().trim();
      $('#special_area').text(value);
    }
  });
});

$('.js-navi-heading').on('click', function () {
  if ($(window).innerWidth() < 768) {
    if ($(this).parent().hasClass('is-active')) {
      $(this).parent().removeClass('is-active');
    } else {
      $(this).parent().addClass('is-active');
    }
  }
});
