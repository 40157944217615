import $ from 'jquery';

const $window = $(window);
const $bodyHtml = $('body,html');
const $html = $('html');
const $body = $('body');
const breakPoint1 = 767;
const breakPoint2 = 1024;

/* ======================================
body fix
====================================== */
let scrollPosi;

function bodyFix() {
  scrollPosi = $(window).scrollTop();
  $body.css({
    position: 'fixed',
    width: '100%',
    'z-index': '1',
    top: -scrollPosi,
  });
}

function bodyFixReset() {
  $body.css({
    position: 'relative',
    width: 'auto',
    top: '0',
  });
  $bodyHtml.scrollTop(scrollPosi);
}

/* ======================================
menu
====================================== */
$(function () {
  $('.js-openMenu').on('click', function () {
    $('.js-nav').toggleClass('is-open');
    if ($('.js-nav').hasClass('is-open')) {
      bodyFix();
    } else {
      bodyFixReset();
    }
  });

  $('.js-closeMenu').on('click', function () {
    $('.js-nav').removeClass('is-open');
    bodyFixReset();
  });
});

/* ======================================
toggle submenu sp
====================================== */
$(function () {
  $('.js-subMenu').each(function () {
    $('.c-header__ttl', this).on('click', function () {
      if ($window.outerWidth() <= breakPoint2) {
        $(this).toggleClass('is-open');
        $(this).closest('.js-subMenu').find('.c-header__info').slideToggle();
      }
    });
  });
});

/* ======================================
toggle phone menu sp
====================================== */
$(function () {
  $('.js-phoneOpen').on('click', function () {
    $('.js-phone').toggleClass('is-open');
    if ($('.js-phone').hasClass('is-open')) {
      bodyFix();
    } else {
      bodyFixReset();
    }
  });

  $('.js-phoneClose').on('click', function () {
    $('.js-phone').removeClass('is-open');
    bodyFixReset();
  });
});

/* ======================================
animation scroll header
====================================== */
let scrollEndTimer, numberh;
let scrollPosPrev = 0;

$(window).on('scroll', function () {
  let scrollPos = $(window).scrollTop();
  const footerTop = $('.c-footer').offset().top;

  if ($(window).outerWidth() < 768) {
    numberh = 50;
  } else {
    numberh = 50;
  }

  if (scrollPos > 100) {
    let _diff = scrollPos - scrollPosPrev;
    if (_diff > 0) {
      if ($(window).outerWidth() > 767) {
        $('.js-header').addClass('is-hidden');
      }
    } else {
      if (scrollPos < footerTop - numberh) {
        $('.js-header').removeClass('is-hidden');
      } else {
        $('.js-header').addClass('is-hidden');
      }
    }

    scrollPosPrev = scrollPos;
    if (scrollPos < footerTop - numberh) {
      clearTimeout(scrollEndTimer);
      scrollEndTimer = setTimeout(function () {
        $('.js-header').removeClass('is-hidden');
      }, 200);
    } else {
      $('.js-header').addClass('is-hidden');
    }

    $('.js-header').addClass('is-hidden2');
  } else {
    $('.js-header').removeClass('is-hidden2');
  }

  if (scrollPos > 200 && scrollPos < footerTop - numberh) {
    if ($(window).outerWidth() < 768) {
      scrollEndTimer = setTimeout(function () {
        if (!$('.js-nav').hasClass('is-open')) {
          $('.js-header').addClass('is-hidden');
        }
      }, 3000);
    }
  }

  if ($body.hasClass('page-top') && !$('.js-nav').hasClass('is-open')) {
    let heightMV = $('.js-mv').outerHeight() - 50;
    if (scrollPos > heightMV) {
      $('.js-header').addClass('is-dark');
    } else {
      $('.js-header').removeClass('is-dark');
    }
  }
});

//===================================================
// format
//===================================================
$(window).on('resize load', function () {
  if ($(window).outerWidth() > breakPoint1) {
    $body.removeAttr('style');
    $('.js-nav').removeClass('is-open');
    $('.js-openMenu').removeClass('is-hidden');
  }
});
