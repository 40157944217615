let elementsArray = document.querySelectorAll(
  '[data-fadein="js-scroll-fade-in"]',
);
window.addEventListener('scroll', fadeIn);
function fadeIn() {
  for (let i = 0; i < elementsArray.length; i++) {
    let elem = elementsArray[i];
    let distInView =
      elem.getBoundingClientRect().top - window.innerHeight + 250;
    if (distInView < 0) {
      elem.classList.add('in-fadein');
    }
  }
}
fadeIn();
